<script>
// @ts-nocheck

	import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  
  export let width = 50;
  export let height = 31;
  export let styles = null;
  export let color = (styles) ? (styles.typingIndicator || styles.cardBackground) : 'black';
  
  let visible = false;

  onMount(() => {
    visible = true;
  })

</script>
{#if visible}
  <div class='float-left' in:fade="{{ duration: 500, delay: 100}}" 
      style={
        `height: ${height}px;`
      }
    >
    <svg width={width} height={height} viewBox="0 0 120 20" xmlns="http://www.w3.org/2000/svg" fill={color}>
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="0.8s"
                    values="9;15;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5"
                    begin="0s" dur="0.8s"
                    values=".5;1;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
  </div>
{/if}
<style>
  div {
    margin-left: 12px;
  }

</style>
