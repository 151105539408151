import TemplateSocialView from '../views/TemplateSocialView.svelte';
// embrace-tup9
// G-0GHM8ZWMJ7
// hTpiHWk8QYat-OV6GiSD7Q
import generateUniqueSessionId from '../lib/generateUniqueSessionId.js';

const slug = "90e27fdfc8be3c17c7a9145b1bf1e4cd|5eb5bd4c52cc25c851aebf2787a0e7ae23708656a0dc7c4c0fd0f20ce4308af6cfaefab1e2fc05e03f1a4833a6539a23"
const buttonBackground = "#00CCCC";
const buttonText = "#000000";
const imageFolderName = "embrace-social";
const checkValue = (value, defaultValue) => {
  return (typeof(value) !== 'undefined') ? value : defaultValue
}
const darkMode = false
const styleParams = {
  mainColor: buttonBackground,
  secondaryColor: buttonText,
}
const buttons = [
  {title: "Poor budget control"},
  {title: "Not meeting objectives"},
  {title: "Not challenging"},
  {title: "Bland creative"}
].sort(() => Math.random() - 0.5);
const style =           {
	// basic colors
	brandColor: checkValue(styleParams.brandColor, '#ffffff'),
	secondaryBrandColor: checkValue(styleParams.mainColor, 'lightblue'),
	chatBackgroundColor: (darkMode) ? '#000000' : '#ffffff', // the background color in the actual chat
	// cards
	cardBackground: (darkMode) ? '#333333' : '#ebe8e8', // the card background
	cardTextColor: checkValue(styleParams.cardTextColor, (darkMode) ?  '#ffffff' : '#000000'),// cards
	borderRadius: '8px', // cards + buttons
	imagesHaveBorders: true,
	// Users
	userTextColor: '#ffffff',// user options
	userBackgroundColor: "#959595",
	// Text Input
	UIButtonBackgroundColor: '#959595', // textInput send button
	UIButtonStrokeColor: 'white', // textInput send button
	textInputColor: '#666666', // textInput options
	textInputBorderRadius: '16px', // textInput options
	typingIndicator: (darkMode) ? 'rgb(200,200,200)': 'rgb(55, 55, 55)',
  }

const config = {
  darkMode: false,
  url: 'https://adchat-ai.uc.r.appspot.com/b',
  productionMode: true,
  hasBorder: false,
  language: 'EN',
  logoURL: `images/${imageFolderName}/logo.png`,
  roundLogo: false,
  headerBackgroundPosition: '16px 50%',
  headerBackgroundColor: 'white',
  introPostback: buttons[0].title,
  // hasHeaderButton: true,
  // headerButtonPostback: "Start Again",
  chatHeightOffset: 80,
  slugMode: true,
  slug: slug,
  headerExitURL: "https://thisisembrace.com/",
  firstFrameExitMode: false,
  firstFrameClickShouldChat: false,
  headerButtonCopy: 'Return to start',
  firstFrameExitURL: '',
  chatTimingMS: 600,
  imagesHaveBorders: true,
  ySpaceBetweenCards: '6px',
  sessionId: generateUniqueSessionId(),
  platform: 'landing-page-en',
  hasInput: false,
  conversationHasInput: false,
  version: '1.0',
  inputConfig: {
    textInputWidth: '80%',
    promptCopy: ""
  },
  firstFrame: {
    inputConfig: {
      textInputWidth: '80%',
      promptCopy: ""
    },
  },
  pixels: {},
  exits: {
  },
  exitRedirects: {
  },
  simulatedConversation: [
    {
      type: 'image',
      src: `./images/${imageFolderName}/first-frame.mp4`,
      imagesHaveBorders: false,
      shouldLoop: true,
    },
    {
      type: 'brandResponse',
      text: `We embrace learning to make us better. So, we’d love to hear what matters most to you when working with an agency.`,
      time: 1500,
    },
    {
      type: 'quickReplies',
      text: `First, what’s your biggest headache when working with an agency? 👇`,
      size: 'large',
      buttons: buttons,
      time: 2000,
    }
  ]
}

const app = new TemplateSocialView({
	target: document.body,
	props: {
        style,
        config,
	}
});

export default app;