<script>
    import { createEventDispatcher } from "svelte";
    import { fade, scale } from "svelte/transition";
    import { quintOut } from "svelte/easing";
	import TypingIndicator from "./TypingIndicator.svelte";

    export let mediaHeight = null;
    export let altTag = null;
    export let src = null;
    export let delay = 750;
    export let style;
    export let makeHotSpot = false;
    export let hasEngaged = false;
    export let shouldLoop = false;

    let dispatch = createEventDispatcher();
    let visible = false;
    let hasDelayed = false;
    let imageHasLoaded = false;
    let isVideo = src.indexOf('mp4') > -1;
    let imagesHaveBorders = false;
    let posterImage = src.replace('.mp4', '.jpg');
    let hasPlayed = false;

    // Watch for changes in hasEngaged using $
    $: {
        if (isVideo && hasEngaged) {
            hasPlayed = false;
            const videoEl = document.getElementById("adchat-loadingImage-videoPlayer");
            if (videoEl) {
                videoEl.play();
            }
        }
    }

    const animate = (node, args) =>{
        if(!args) return {};
		args.cond ? fade(node, args) : scale(node, args);}

    function imageLoadedHandler(){
        imageHasLoaded = true;
        if(hasDelayed)
            visible = true
            dispatch('imageLoaded', src)
    }

    function handleClick(){
        dispatch('imageClick', src)
    }

    function getVideoUrl(filename) {
        if (window.Enabler && Enabler && Enabler.isServingInLiveEnvironment()) {
            return Enabler.getUrl(filename); 
        } else {
            return filename;
        }
    }

    setTimeout(() => {
        hasDelayed = true;
        if(imageHasLoaded)
            visible = true
            dispatch('imageLoaded', src)
    }, delay)

    function videoCompleteHandler(){
        hasPlayed = true;
    }

</script>

<div class="relative"
        on:click={handleClick} 
        on:keydown={handleClick}>
    {#if !visible}
            <div class="absolute top-0 left-0 -mx-2" >
                    <TypingIndicator styles={style}/>
            </div>  
    {/if}
    {#if !isVideo}
        {#if src && (imageHasLoaded && hasDelayed)}
            <img class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left ${(visible) ? 'visible' : 'invisible'}  ${(makeHotSpot) ? 'cursor-pointer' : ''}`}
                alt={altTag}
                in:animate={{ duration: 250, start: 0.75, easing: quintOut }}
                style={`max-height: ${mediaHeight}px;`}
                src={src} 
                on:load={imageLoadedHandler}
            />
        {:else}
        {#if src && (hasDelayed)}
            <img class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left} ${(visible) ? 'visible' : 'invisible'}`}
                alt={altTag}
                style={`
                    max-height: ${mediaHeight}px;`}
                src={src} 
                on:load={imageLoadedHandler}
            />
        {/if}
        {/if}
    {:else}
        {#if !hasPlayed}
            <!-- svelte-ignore a11y-media-has-caption -->
            <video 
                id="adchat-loadingImage-videoPlayer"
                class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left h-auto ${(makeHotSpot) ? 'cursor-pointer' : ''}`}
                height={mediaHeight}
                preload autoplay muted playsinline 
                poster={posterImage}
                loop={hasEngaged || shouldLoop}
                style={`max-height: ${mediaHeight}px;`}
                on:loadeddata={imageLoadedHandler}
                in:animate={(hasEngaged) ? null : { duration: 250, start: 0.75, easing: quintOut }}
                on:ended={videoCompleteHandler}
                src={getVideoUrl(src)} 
                on:load={imageLoadedHandler}
            />
        {:else }
            <img class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left ${(visible) ? 'visible' : 'invisible'}  ${(makeHotSpot) ? 'cursor-pointer' : ''}`}
            alt={altTag}
            in:fade={{ duration: 500 }}
            style={`max-height: ${mediaHeight}px;`}
            src={posterImage} 
        />
        {/if}
    {/if}
</div>