<script>
	import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
	import { onMount, afterUpdate, createEventDispatcher } from "svelte";
	import Card from "./Card.svelte";
	import Carousel from "./Carousel.svelte";
	import resize from "svelte-actions-resize";
	import displayedCardCount from "../../lib/displayedCardCount";

	export let cards;
	export let style;
	export let width = null;
	export let rtl = false;
	export let imagesHaveBorders = true;
	export let carouselArrowButtonY = "45px";
	export let analytics = null;
	export let ySpaceBetweenCards = "2px";
	export let darkMode = false;
	export let compactMode = false;
	export let centerCardButtons = true;
	export let imageHeight = null;

	let clientWidth;
	let showRight;
	let currentSlide = 0;
	let cardWidth = "";
	let maxHeight = 0;
	let currentIndex = 0;
	let subHeadMinHeight = null;

	$: {
	  maxHeight = 0;
	}

	const dispatch = createEventDispatcher();

	afterUpdate(() => {
	  updateCardWidth();
	});

	function updateCardWidth(event) {
		const scale = clientWidth <= 300 ? 0.86 : 0.95;
	  	showRight = showRightIcon();
	  	cardWidth = Math.ceil((clientWidth * scale) / displayedCardCount(clientWidth)) - 6 + "px";
		if(event)
			dispatch("resize", event);
	}

	function handleSlideChange(event) {
	  	recordSwipe(event);
	  	currentSlide = event.detail.currentSlide;
	  	showRight = showRightIcon();
	}

	function recordSwipe(event) {
	  if (analytics) {
	    if (!currentSlide || event.detail.currentSlide > currentSlide) {
	      analytics.registerGenericEvent("carouselRight");
	    } else {
	      analytics.registerGenericEvent("carouselLeft");
	    }
		currentSlide = event.detail.currentSlide
	  }
	}

	function handleOpenURL(event) {
		if(currentSlide === event.detail.index){
	  		dispatch("openUrl", event.detail.url);
		}
	}

	function showRightIcon() {
	  const carouselWidth = parseInt(cardWidth) * cards.length;
	  const isWideEnough = carouselWidth >= clientWidth;
	  return (
	    isWideEnough &&
	    currentSlide < cards.length - displayedCardCount(clientWidth)
	  );
	}

	function handlePostback(event) {
	  dispatch("postback", event.detail);
	}

	function handleCardResize(event) {
	  maxHeight = Math.max(maxHeight, event.detail);
	  dispatch("resize", event.detail);
	}

	function handleSubHeadMeasured(event){
		const height = event.detail;
		subHeadMinHeight = Math.max(height, subHeadMinHeight)
	}
</script>

<div class="carousel-container relative visible"
		bind:clientWidth={clientWidth}
		style={`
			width: ${(clientWidth < 300) ? '86%' : '98%'};
			height: ${maxHeight}px;
		`}
		 use:resize
		 on:resize={updateCardWidth}
	 >
	{#if cardWidth}
	  	<Carousel
			currentIndex={currentIndex}
			perPage={displayedCardCount(clientWidth)}
			duration={250}
			loop={false}
			on:change={handleSlideChange}
			draggable={cards.length > 1}
			arrowButtonY={carouselArrowButtonY}
	    >
			<div class="control left-arrow" slot="left-control">
				{#if currentSlide !== 0 && cards.length > 1}
					<div class="drop-shadow icon-wrapper rounded-full color-red display-block relative h-[34px] w-[34px]" aria-label="left arrow"
							style={`background-color: ${darkMode ? '#6C6C6C' : 'white'};color: ${!darkMode ? '#6C6C6C' : 'white'};`}>
						<ChevronLeftIcon darkMode={darkMode}/>
					</div>
				{/if}
			</div>
			{#each cards as card, index}
				<div class="slide-content flex mb-[1px]">
				<Card
					on:subHeadMeasured={handleSubHeadMeasured}
					subHeadMinHeight={subHeadMinHeight}
					width={width}
					styles={style}
					margin={"0 12px 0 0"}
					src={card.src}
					compactMode={compactMode}
					text={card.text}
					centerCardButtons={centerCardButtons}
					headline={ card.headline }
					rtl={rtl}
					index={index}
					minHeight={maxHeight}
					subHeadline={card.subHeadline}
					buttons={card.buttons}
					imagesHaveBorders={imagesHaveBorders}
					ySpaceBetweenCards={ySpaceBetweenCards}
					imageHeight={imageHeight}
					on:postback={handlePostback}
					on:openUrl={handleOpenURL}
					on:instruction={(event) => dispatch('instruction', event.detail)}
					on:resize={handleCardResize}
				/>
				</div>
			{/each}
			<span class="control right-arrow" slot="right-control">
	      {#if showRight}
				<div class="drop-shadow icon-wrapper bg-white rounded-full display-block relative h-[34px] w-[34px]"
						style={`background-color: ${darkMode ? '#6C6C6C' : 'white'};color: ${!darkMode ? '#6C6C6C' : 'white'};`}
					>
					<ChevronRightIcon />
				</div>
	      {/if}
	  </Carousel>
	{/if}
</div>
<style>
	.control :global(svg) {
	  width: 20px;
	  height: 20px;
	  cursor: pointer;
	  margin: 0;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  -ms-transform: translate(-50%, -50%);
	  transform: translate(-50%, -50%);
	}
</style>