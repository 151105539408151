<script>
  import { afterUpdate } from 'svelte';
  import { createEventDispatcher } from 'svelte';
	import { bounceOut } from 'svelte/easing';
	import { fade, scale } from 'svelte/transition';
  import "../../styles/tailwind.css";

  const dispatch = createEventDispatcher();

  export let text;
  export let hasBottomMargin;
  export let style;
  export let rtl = false;
  export let reverse = false;

  let clientHeight;
  let backgroundColor = (reverse) ? (style.userTextColor || style.cardBackground) : (style.userBackgroundColor || style.brandColor);
  let textColor = (reverse) ? (style.userBackgroundColor || style.brandColor): (style.userTextColor || style.cardBackground);

  afterUpdate(() => {
    const height = clientHeight;
    dispatch('measureComplete', height)
  })


  const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);

</script>
<div class="w-full min-h-[34px]">
<div class={`origin-bottom-right text-sm mr-3 user-bubble float-right mb-1 px-3 py-2 max-w-[70%] rounded-2xl font-normal leading-custom ${(hasBottomMargin) ? 'user-bubble-last' : 'mt-1'}`}
    bind:clientHeight   
    in:animate={{ duration: 500, start: 1.4, easing: bounceOut }}
    style={`
        background-color: ${backgroundColor};
        color: ${textColor};
        ${(reverse) ? `border: 1px solid ${textColor};`: ''}
        direction: ${(rtl) ? 'rtl' :  ''};
      `}>
    { text }
  </div>
</div>
<style>
  .user-bubble-last {
    margin-bottom: 8px;
  }
</style>