<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let enabled = false;
  export let style;
  let rotation = 0;

  function onClick(event) {
    if(enabled){
      dispatch('click', event);
    }
  }

  function onMouseOver(event){
    if(enabled){
      rotation = 90;
    }
  }

  function onMouseOut(event){
    rotation = 0;
  }
  console.log(style)
</script>
<div
  on:click={onClick}
  on:mouseover={onMouseOver}
  on:mouseout={onMouseOut}
  on:keydown={onClick}
  on:blur={onMouseOut}
  on:focus={onMouseOver}
  class="drop-shadow w-[22px] h-[22px] rounded-full absolute top-[50%] left-[50%]"
  style={
  ` transform: translate(-50%, -50%) rotate(${rotation}deg);
    cursor: ${(enabled) ? 'pointer' : 'default'};
    background-color: ${style.UIButtonBackgroundColor};
    stroke: ${style.UIButtonStrokeColor};
    opacity: ${(enabled) ? '1' : '0.7'};
  `}>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 91.3 88.5" style="width: 10px; height: 10px; position: absolute; top: 6px; left: 6px;">
    <line x1="45.7" y1="7" x2="45.7" y2="81.5"></line>
    <line x1="45.7" y1="7" x2="84.3" y2="45.7"></line>
    <line x1="45.7" y1="7" x2="7" y2="45.7"></line>
  </svg>
</div>

<style>
line{
  stroke-width: 14px;
  stroke-linecap: round;
}
</style>
