export default class StaggeredContent{

  constructor(content = [], interval = 100, updateCallback, addDelay = false){
    this.interval = interval;
    this.masterList = content;
    this.updateCallback = updateCallback;
    this.addDelay = addDelay;
  }

  add(items){
    let timeCounter = 0;
    items.forEach((item, count) => {
        setTimeout(() => {
          this.masterList.push(item);
          let turnOffLoadMode = (count >= (items.length - 1));
          this.updateCallback(this.masterList, turnOffLoadMode, count);
        }, timeCounter)
        timeCounter += (item.time) ? item.time : this.computeAnimationTime(item)
    })
  }


  computeAnimationTime(item){
    if(item.type === 'quickReplies'){
      let time = item.buttons.length * this.interval
      if(item.title && item.title.length > 0)
        time += this.interval;
        return time;
    }
    return this.interval;
  }

}
