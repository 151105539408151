<script>
	import TypingIndicator from "../components/core/TypingIndicator.svelte";

    export let delay = 0;
    export let includeIndicator = false;
    export let indicatorColor = "#fff ";
    export let indicatorDelay = 0;
    let loaded = false;
    let display = false;

    setTimeout(async () => {
        loaded = true
    }, delay)

    setTimeout(() => {
        display = true
    }, indicatorDelay)
</script>
<div class={`${$$props.class  || 'delay-box'}`}>
    {#if loaded}
    	<slot></slot>
    {:else if includeIndicator && display}
        <TypingIndicator color={indicatorColor} />
    {/if}
</div>