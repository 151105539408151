
<script>
  export let pixelName = '';
  export let pixels = {};
</script>

  <img
    class='pixel-tracking'
    src={pixels[pixelName]}
    border="0"
    height="1"
    width="1"
    alt=""
  />
