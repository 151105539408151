// slugMode
// slug
// url
// fileName
// platform
// sessionId

// projectId
// customDimensions
class Analytics{

    constructor(config){
      this.config = config
    }

    exit(url, label) {
      console.log('Analytics: Exit', url)
      const http = new XMLHttpRequest();
      const config = this.config;
      const fileName = (config.slugMode) ? config.slug : config.projectId;
      const base = (config.slugMode) ? `${config.url}/hash/${fileName}.json` : `${config.url}/chat/${fileName}.json`
      const params = `?platform=${config.platform}&version=${
        config.version
      }&session=${config.sessionId}&url=${encodeURIComponent(url)}`;
      http.open("POST", encodeURI(base + params), true);
      http.onreadystatechange = function() {
        if (http.readyState == 4 && http.status == 200) {
          return http.responseText;
        }
      };
      http.send(params);
    }

    registerImpression(){
      let str = ''
      if(this.config.customDimensions){
        let count = 1
        this.config.customDimensions.forEach((dimension) =>
          str += `&cd${count}=` + encodeURIComponent(dimension)
        )
      }
      const config = this.config
      const fileName = (config.slugMode) ? config.slug : config.projectId;
      const url = `${config.url}/count/${fileName}.json?platform=${config.adId}&session=${config.sessionId}${str}`
      const http = new XMLHttpRequest();
      http.open('GET', url);
      http.send()
    }

    registerGenericEvent(eventName, intentName = null){
      console.log('Analytics: Generic', ' | ', eventName, ' | ', intentName)
      let str = ''
      if(this.config.customDimensions){
        let count = 1
        this.config.customDimensions.forEach((dimension) =>
          str += `&cd${count}=` + encodeURIComponent(dimension)
        )
      }
      if(intentName){
        str += `&platform=${intentName}`
      }
      const config = this.config
      const fileName = (config.slugMode) ? config.slug : config.projectId;
      const url = `${config.url}/generic/${fileName}.json?platform=${config.adId}&eventName=${eventName}&session=${config.sessionId}${str}`
      const http = new XMLHttpRequest();
      http.open('GET', url);
      http.send()
    }
}
export default Analytics;
