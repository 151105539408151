<script>
	import Siema from "siema";
	import { onMount, createEventDispatcher } from "svelte";

	export let perPage = 3;
	export let loop = true;
	export let autoplay = 0;
	export let duration = 200;
	export let easing = "ease-out";
	export let startIndex = 0;
	export let draggable = true;
	export let multipleDrag = true;
	export let controls = true;
	export let threshold = 20;
	export let rtl = false;
	export let arrowButtonY = "70px";
	export let currentIndex = 0;

	let rightLeft = "102%";
	let siema;
	let controller;
	let timer;
	let clientWidth;
	let buttonClasses = "absolute w-[40px] h-[40px] top-[50%] -mt-[-20px] border-none bg-transparent opacity-100"
	let leftEnabled = false
	let rightEnabled = true

	const dispatch = createEventDispatcher();
	currentIndex = startIndex;

	$: pips = controller ? controller.innerElements : [];
	$: currentPerPage = controller ? controller.perPage : perPage;
	$: totalDots = controller
	  ? Math.ceil(controller.innerElements.length / currentPerPage)
	  : [];
	$: currentIndex = go(currentIndex);

	onMount(() => {
	  controller = new Siema({
	    selector: siema,
	    perPage: typeof perPage === "object" ? perPage : Number(perPage),
	    loop,
	    duration,
	    easing,
	    startIndex,
	    draggable,
	    multipleDrag,
	    threshold,
	    rtl,
	    onChange: handleChange
	  });

	  // if(autoplay) {
	  // 	timer = setInterval(right, autoplay);
	  // }
	  Array.from(document.getElementsByClassName("slides")).forEach(slide => {
	    slide.style.overflow = "initial";
	  });

	  return () => {
	    // autoplay && clearInterval(timer)
	    controller.destroy();
	  };
	});

	export function isDotActive(currentIndex, dotIndex) {
	  if (currentIndex < 0) currentIndex = pips.length + currentIndex;
	  return (
	    currentIndex >= dotIndex * currentPerPage &&
	    currentIndex < dotIndex * currentPerPage + currentPerPage
	  );
	}

	export function left() {
	  controller.prev();
	}

	export function right() {
	  controller.next();
	}

	export function go(index) {
	  if (controller) controller.goTo(index);
	}

	export function pause() {
	  clearInterval(timer);
	}

	export function resume() {
	  if (autoplay) {
	    timer = setInterval(right, autoplay);
	  }
	}

	function getRightArrowPosition(width) {
	  if (width <= 300) {
	    return "105%";
	  } else if (width <= 500) {
	    return "97%";
	  } else if (width <= 600) {
	    return "100%";
	  }
	  return "102%";
	}

	function handleChange(event) {
	  currentIndex = controller.currentSlide;
		leftEnabled = currentIndex > 0
		rightEnabled = currentIndex < controller.innerElements.length - 1
	  dispatch("change", {
	    currentSlide: controller.currentSlide,
	    slideCount: controller.innerElements.length
	  });
	}
</script>

<div class="carousel relative w-[100%]l" bind:clientWidth={clientWidth}>
	<div class="slides" bind:this={siema} role="presentation">
		<slot></slot>
	</div>
	{#if controls}

	{#if leftEnabled}
		<button class={`left ${buttonClasses} left-0`}
				style={`top: ${arrowButtonY};`}
				on:click={left}
				aria-label="left">
			<slot name="left-control"></slot>
		</button>
		{/if}
		{#if rightEnabled}
			<button class={`right ${buttonClasses}`}
					style={`left: ${getRightArrowPosition(clientWidth)};top: ${arrowButtonY};`}
					on:click={right}
					aria-label="right">
				<slot name="right-control"></slot>
			</button>
		{/if}
	{/if}
</div>

<style>
	button:hover {
	  opacity: 0.7;
	}

</style>


<!--
	ul li {
		margin: 6px;
		border-radius: 100%;
		background-color: rgba(255,255,255,0.5);
		height: 8px;
		width: 8px;
	}
ul li:hover {
	background-color: rgba(255,255,255,0.85);
}

ul li.active {
	background-color: rgba(255,255,255,1);
} -->
