<script>
	import QuickReply from './QuickReplies.svelte';
  import TypingIndicator from "./TypingIndicator.svelte";
  import BrandResponse from "./BrandResponse.svelte";
  import UserResponse from "./UserResponse.svelte";
  import CardCarousel from "./CardCarousel.svelte";
  import { createEventDispatcher } from "svelte";
	import QuickReplies from './QuickReplies.svelte';

  const dispatch = createEventDispatcher();

  export let width;
  export let styles;
  export let content = [];
  export let isLoading = false;
  export let rtl = false;
  export let ySpaceBetweenCards;
  export let carouselArrowButtonY = "65px";
  export let analytics = null;
  export let customPayloadRenderer;
  export let centerCardButtons = true;
  export let shouldAnimate = false;
  export let imageHeight = null;
  export let darkMode = false;
  export let chatTimingMS = 250;

  let lastClientHeight = null;

  $: {
    if(clientHeight !== lastClientHeight){
      lastClientHeight = clientHeight
      dispatch('resize', {width: clientWidth, height: clientHeight})
    }
  }
  
  let clientWidth = null;
  let clientHeight;

  // 1: 87%, 2: 43%, 3: 76%
  let wrapperWidth = clientWidth && clientWidth <= 300 ? "100%" : "90%";


  function isLast(index, isUser) {
    const nextContent = content[index + 1];
    if (!nextContent) return false;
    const nextIsBrand = nextContent.type !== "userResponse";
    if (isUser && !nextIsBrand) {
      return false;
    }
    if (!isUser && nextIsBrand) {
      return false;
    }
    return true;
  }

  function handlePostback(event) {
    dispatch("postback", event.detail);
  }

  function handleChange(event) {
    dispatch("change", event.detail);
  }

  function handleInstruction(event) {
    dispatch("instruction", event.detail);
  }

  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  function handleResize(event) {
    dispatch("resize", event.detail);
  }
  
</script>
<div class="holder relative"
    bind:clientWidth
    bind:clientHeight
    style={`
      width: ${width};`
    }
  >
  {#each content as item, index}
    <div class="item w-full clear-both">
      {#if item.type === 'userResponse'}
        <UserResponse
          style={styles}
          text={item.text}
          hasBottomMargin={isLast(index, true)}
          rtl={rtl}
          ySpaceBetweenCards={ySpaceBetweenCards}
        />
      {/if}
      {#if item.type === 'customPayload' && customPayloadRenderer}
        <svelte:component
          this={ customPayloadRenderer(item).component}
          {...customPayloadRenderer(item).props}
          item={item}
          style={styles}
          on:postback={handlePostback}
          on:change={handleChange}
          on:selection={(event)=>handlePostback(event)}
          on:openUrl={handleOpenURL}
        />
      {/if}
      {#if item.type === 'button'}
        <QuickReply 
          style={styles} 
          label={item.text} 
          postback={item.text}
          on:postback={(event)=>handlePostback(event)}
          on:openUrl={handleOpenURL}
          animationSpeed={chatTimingMS}
        />
      {/if}
      {#if item.type === 'carousel'}
        <div class="carousel-wrapper mb-1"
          style={`margin-left: 12px;
                  width: ${wrapperWidth};`}
        >
          <CardCarousel
            style={styles}
            cards={item.items}
            rtl={rtl}
            darkMode={darkMode}
            centerCardButtons={centerCardButtons}
            on:openUrl={handleOpenURL}
            on:postback={handlePostback}
            on:instruction={handleInstruction}
            carouselArrowButtonY={carouselArrowButtonY}
            ySpaceBetweenCards={ySpaceBetweenCards}
            analytics={analytics}
            imagesHaveBorder={item.imagesHaveBorder}
            imageHeight={imageHeight}
          />
        </div>
      {/if}
      {#if item.type === 'brandResponse'}
        <BrandResponse
          styles={styles}
          text={item.text}
          width={item.width}
          hasBottomMargin={isLast(index, false)}
          rtl={rtl}
          ySpaceBetweenCards={ySpaceBetweenCards}
        />
      {/if}
      {#if item.type === 'card'}
        <div class="carousel-wrapper mb-1"
          style={`margin-left: 12px;
                  width: ${wrapperWidth};`
              }
        >
          <CardCarousel
            style={styles}
            cards={[item]}
            rtl={rtl}
            darkMode={darkMode}
            imagesHaveBorders={item.imagesHaveBorders}
            centerCardButtons={centerCardButtons}
            ySpaceBetweenCards={ySpaceBetweenCards}
            analytics={analytics}
            width={item.width}
            compactMode={item.compactMode}
            imageHeight={imageHeight}
            on:openUrl={handleOpenURL}
            on:postback={handlePostback}
            on:instruction={handleInstruction}
            on:resize={handleResize}
          />
        </div>
      {/if}

      {#if item.type === 'quickReplies'}
          <QuickReplies
            style={styles}
            item={item}
            rtl={rtl}
            centerCardButtons={centerCardButtons}
            animationSpeed={chatTimingMS}
            analytics={analytics}
            compactMode={item.compactMode}
            animatedBuild={shouldAnimate}
            on:openUrl={handleOpenURL}
            on:postback={handlePostback}
            on:instruction={handleInstruction}
          />
      {/if}

    </div>
  {/each}
  {#if isLoading && content.length && content[content.length - 1].type !== 'quickReplies'}    
    {#key content.length}
      <div class="clearfix">
        <TypingIndicator
          styles={styles}
        />
      </div>
    {/key}
  {/if}
</div>
<style>
  :global(sup){
    font-size: 8px;
    line-height: 1;
    display: inline-block;
  }
</style>
