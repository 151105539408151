<script>
  import IconButton from "./IconButton.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let style;
  export let inputConfig = {};

  let textValue = '';
  let hasFocus = false;
  let textHasBeenSubmitted = false;
  let lastTextValue = "";

  function textChangeHandler(event) {
    lastTextValue = textValue;
    dispatch("textChange", textValue);
  }

  function submitHandler(event) {
    submitText();
  }

  function submitText() {
    const valueToSubmit = textValue;
    if (valueToSubmit.trim().length) {
      textHasBeenSubmitted = true;
      dispatch("textSubmit", valueToSubmit.trim());
      textValue = "";
    }
  }

  function blurHandler() {
    if (textValue === "" && !textHasBeenSubmitted) {
      textValue = inputConfig.promptCopy;
    }
    hasFocus = false;
  }

  function focusHandler() {
    hasFocus = true;
    if (textValue === inputConfig.promptCopy) {
      textValue = "";
    }
  }

  function handleKeydown(event) {
    if (!hasFocus) return null;
    let char = typeof event !== "undefined" ? event.keyCode : event.which;
    if (char == 13 && textValue.length > 0) {
      submitText();
    }
  }

</script>
<svelte:window on:keydown={handleKeydown}/>
<div class="w-full" style={`
  background-color: ${style.chatBackgroundColor || "white"};`}>
<div class="relative flex m-auto w-full"
  style={`
    width: ${inputConfig.textInputWidth};
  `}>
  <div id="text-input-container">
    <div class="w-full h-full items-center flex border rounded-lg bg-white"
          style={`border-color: ${style.textInputColor};
                  border-radius: ${style.textInputBorderRadius || "14px"}
          `}
    >
      <input class="border-transparent focus:border-transparent focus:ring-0 w-full my-0 mx-3 border-none h-[36px] text-sm placeholder:adchat-light-gray"
        placeholder={inputConfig.promptCopy}
        style={`
          background-color: rgba(1,1,1,0);
          color: ${style.textInputColor};
        `}
        type="text"
        bind:value={textValue}
        on:input="{textChangeHandler}"
        on:focus={focusHandler}
        on:blur={blurHandler}
      />
    </div>
  </div>
  <div id="send-button-container">
    <IconButton
      enabled={(textValue !== inputConfig.promptCopy) && textValue.length !== 0}
      style={style}
      on:click={submitHandler}
    />
  </div>
</div>
</div>
<style>
  #send-button-container {
    width: 22px;
    position: relative;
    margin: 0 auto;
  }
  #text-input-container {
    flex-grow: 1;
    -ms-flex-positive: 1; /* IE 10 */
    border: 15px solid rgba(1, 1, 1, 0);
    border-left: 0;
  }
  input::placeholder{
    color: #6c6c6c;
  }
</style>
