<script>
  import Image from "./Image.svelte";
  import SimpleButton from "./SimpleButton.svelte";

  import { createEventDispatcher, afterUpdate, onMount } from "svelte";

  import { fly } from 'svelte/transition';
  import { quadOut } from 'svelte/easing'
  import snarkdown from "snarkdown";

  export let styles;
  export let src = null;
  export let text = null;
  export let headline = null;
  export let subHeadline = null;
  export let buttons = null;
  export let rtl = false;
  export let isSquare = false;
  export let width = 'auto';
  export let margin = "0 12px";
  export let imagesHaveBorders = false;
  export let cardWidth = "auto";
  export let ySpaceBetweenCards = "2px";
  export let compactMode = false;
  export let subHeadMinHeight = null;
  export let centerCardButtons = true;
  export let index = null;

  const BUTTON_DELAY = 250

  let bgColor = headline || text || buttons ? styles.cardBackground : null;
  let hasLoaded = !src;
  let idValue = "card-" + Math.random() * 100000;
  let clientHeight = null;
  
  const dispatch = createEventDispatcher();

  onMount(async () => {
    if(buttons && buttons.length)
      setTimeout(() => {
        measureCardHeight()
      }, BUTTON_DELAY * (buttons.length - 1))
  });


  afterUpdate(async () => {
    const subHead = document.getElementsByClassName('sub-headline')[0]
    if(document.getElementsByClassName('sub-headline')[0]){
      dispatch('subHeadMeasured', subHead.clientHeight)
    }
    measureCardHeight()
  });

  function imageLoadedHandler(event) {
    hasLoaded = true;
    measureCardHeight();
  }

  function measureCardHeight() {
    if (document.getElementById(idValue))
      clientHeight = document.getElementById(idValue).scrollHeight;
      dispatch("resize", clientHeight);
  }

  function buttonPostbackHandler(event) {
    if (event.detail.indexOf("instruction:") !== -1) {
      dispatch("instruction", event.detail.split("instruction:")[1]);
    } else {
      dispatch("postback", event.detail);
    }
  }

  function buttonOpenUrlHandler(event) {
    dispatch("openUrl", {url: event.detail, index:index});
  }

  function imageClickHandler() {
    if (buttons && buttons.length === 1) {
      dispatch("openUrl", {
        url: buttons[0].postback, 
        index: index
      });
    }
  }

</script>
<div class="card flex flex-col  min-w-[220px] w-full pb-0 rounded-2xl font-normal leading-buttonrelative overflow-hidden basis-auto"
  bind:clientHeight={clientHeight}
  id={idValue}
  style={`
    ${(width) ? `width: ${width};` : ""}
    margin: ${margin};
    ${(styles.cardBorder) ? `border:  ${styles.cardBorder};` : ""}
    ${(cardWidth)?`max-width: ${cardWidth};`:'max-width: initial;'}
    border: ${(imagesHaveBorders) ? '1px solid ' + styles.cardBackground : null };
    background-color: ${bgColor};
    margin-bottom: ${ySpaceBetweenCards};
  `}
  >
  {#if src}
    <div class={`image-wrapper overflow-hidden min-h-[112px] ${(buttons && buttons.length) ? 'cursor-pointer' : ''}`}
      on:click={imageClickHandler}
      on:keydown={imageClickHandler}>
      <Image
        src={src}
        on:loaded={imageLoadedHandler}
        isRounded={!text && !headline && !subHeadline}
        height={(isSquare) ? 573 : 300}
      />
    </div>
  {/if}
  {#if text && text.trim().length && !headline && !subHeadline}
    <p class={`copy-wrapper ${(rtl) ? 'rtl' : ''} p-2 ${(buttons && buttons.length) ? 'pb-1.5' : 'pb-2'} m-0 ${(!subHeadline && !headline) ? 'text-sm' : 'text-cardBody'} snarkdown`}
      style={
        `${(!src) ? 'margin: 2px 0;' : '' }
        color: ${(styles.cardTextColor) ? styles.cardTextColor : styles.secondaryBrandColor};`
        }

    > 
      { @html snarkdown(text) }
    </p>
  {:else if headline || subHeadline || text }
    <div
      class="copy-wrapper text-xs pb-0 leading-high"
      style={`
          ${(headline && (subHeadline || src )) ? 'padding-bottom: 8px;' : ''}
          color: ${(styles.cardTextColor) ? styles.cardTextColor : styles.secondaryBrandColor};
        `}>
      {#if headline}
        <h5 class={`headline text-sm font-bold p-0 px-2 m-0 my-0 leading-pixel16 my-1 ${(rtl) ? 'rtl' : ''} snarkdown`} 
            style={`
              color: ${(styles.cardTextColor) ? styles.cardTextColor : styles.secondaryBrandColor};
            `}>
          { @html snarkdown(headline) }
        </h5>
      {/if}
      {#if subHeadline}
        <h6 class={`opacity-60 sub-headline text-xs font-normal py-0 px-2 m-auto ${(rtl) ? 'rtl' : ''} snarkdown`}
          style={`
            ${(subHeadMinHeight) ? `min-height: ${subHeadMinHeight}px;` : ''}
          `}>
          { @html snarkdown(subHeadline) }
        </h6>
      {/if}
      {#if text && text.trim().length}
        <p class={`${(rtl) ? 'rtl' : ''} p-2 pb-0 m-0 text-xs opacity-60 snarkdown`}
          style={`margin: ${(!src) ? '2px 0' : '0' }`}
        >
          { @html snarkdown(text) }
        </p>
      {/if}
    </div>
  {/if}

  {#if buttons && buttons.length}
    <div class={`inline pb-1 leading-high ${(!headline && !subHeadline && !text) ? 'mt-2' : ''} ${(compactMode) ? ' ml-0.5 inline' : ''}
      `}>
      {#each buttons as button, index}
        <div
          in:fly="{(buttons.length > 1) ? { x: 20, duration: BUTTON_DELAY, y: 2, easing: quadOut, delay: BUTTON_DELAY * index} : {}}"  
          style={` ${(compactMode) ? 'display:inline;' : ''}`}>
          <SimpleButton
            label={button.title}
            styles={styles}
            postback={button.postback || button.title}
            url={button.url || null}
            on:postback={buttonPostbackHandler}
            on:openUrl={buttonOpenUrlHandler}
            centerMode={centerCardButtons}
            compactMode={compactMode}
            rtl={rtl}
          />
        </div>  
      {/each}
    </div>
  {/if}
</div>