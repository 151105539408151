import DialogFlowTransformer from './DialogFlowTransformer.js'
class ChatInterface{
  constructor(){
    this.isFirstClick = true
  }
  customDimensionsToGET(customDimensions){
    if(!customDimensions)
      return ''
    let str = ''
    let count = 1
    customDimensions.forEach((dimension) =>
      str += `&cd${count}=` + encodeURIComponent(dimension)
    )
    return str
  }
  chat(message, config, intentNameCallback, rtl = false, customDimensions = null){
    const isFirstClick = this.isFirstClick
    this.isFirstClick = false
    const scope = this
    return new Promise(function (resolve, reject) {
      const dialogFlowTransformer = new DialogFlowTransformer(config.rtl);
      const http = new XMLHttpRequest();
      const theMessage = encodeURIComponent(message);
      const theSession = encodeURIComponent(config.sessionId);
      const fileName = (config.slugMode) ? config.slug : config.projectId;
      const folder = (config.slugMode) ? 'hash' : 'chat';
      const url = (config.productionMode) ? `${config.url}/${folder}/${fileName}.json` : `${config.url}/${folder}/${config.projectId}`
      const params = `text=${theMessage}&session=${theSession}&platform=${config.platform}&version=${config.version}&projectId=${config.projectName}&firstClick=${isFirstClick}` + scope.customDimensionsToGET(customDimensions);
      if(config.productionMode){
        http.open('GET', url + '?' + params, true);
      } else {
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      }
      http.onreadystatechange = async () => {
        if(http.readyState === 4 && http.status === 200) {
          const response = JSON.parse(http.responseText)
          resolve(dialogFlowTransformer.transform(response, intentNameCallback, rtl))
        }
      }
      http.send(params);
    });

  }
}
export default ChatInterface;
