<script>
	import { createEventDispatcher, afterUpdate } from 'svelte';
    import { bounceOut } from 'svelte/easing';
	import { fly } from 'svelte/transition';
	import BrandResponse from './BrandResponse.svelte';
	import SimpleButton from './SimpleButton.svelte';
	import DelayBox from '../../better/DelayBox.svelte';
    import snarkdown from 'snarkdown';
	import { scale } from 'svelte/transition';

    export let item;
    export let style;
    export let url = null;
    export let large = false;
    export let rtl = false;
    export let animatedBuild = false;
    export let width = null;
    export let animationSpeed;

    const delayForTitle = (item.text && item.text.length) ? 1 : 0;
    const dispatch = createEventDispatcher();

  const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);

    let hasMeasured = false;
    let clientHeight = null;

    if(item.size === 'large'){
        large = true;
    }

    if(item.randomize){
        const randomizedButtons = item.buttons.sort(() => Math.random() - 0.5);
        item.buttons = randomizedButtons
    }

    afterUpdate(() => {
        if (clientHeight) {
            hasMeasured = true;
            dispatch('measure', {height: clientHeight})
        }
    })

    function handleOpenUrl(event) {
        let url = event.detail;
        event.preventDefault();
        dispatch("openUrl", url);
    }
    
    function handleClick(event) {
        let postback = event.detail;
        event.preventDefault();
        if (url && url.length) {
            dispatch("openUrl", url);
            return;
        }
        let expression = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi;
        let regex = new RegExp(expression);
        if (postback.match(regex)) {
            dispatch("openUrl", postback);
        } else {
            dispatch("postback", postback);
        }
    }

</script>
{#if hasMeasured}
    <div class="min-h-[34px]" 
        style={style} >
        {#if item.text && item.text.trim().length > 0}
        <div class="button-text origin-top-left text-sm whitespace-prewrap user-bubble mb-1 px-3 py-2 inline-block rounded-2xl leading-custom content-border mx-3 snarkdown" 
  in:animate={{ duration: 300, delay: 0, start: 1.05, easing: bounceOut }}
  style={`
    background-color: ${style.cardBackground};
    ${(style.cardBorder) ? "border: " + style.cardBorder + ";" : ""}
    color: ${(style.cardTextColor ? style.cardTextColor : style.secondaryBrandColor)};
    ${(rtl) ? 'direction: rtl;' : ''}
    width: ${(width) ? width : 'auto'};
    max-width: ${(parseInt(width) > 80) ? width : '83%'};
  `}>
  {@html snarkdown(item.text)}
</div>

        {/if}
        {#each item.buttons as button, i}
            <div>
                <DelayBox 
                    delay={animatedBuild ? (i + delayForTitle) * animationSpeed : 0} 
                    indicatorDelay={animatedBuild ? ((i + delayForTitle) * animationSpeed) - (500 * delayForTitle): 0}
                    includeIndicator={true}
                    style={style}
                    indicatorColor={style.typingIndicator || style.primaryColor}>
                    <div  class="mx-3"
                        in:fly={(animatedBuild) ? {x: 10, duration: 250}: {}}>
                        <SimpleButton
                            on:postback={handleClick} 
                            on:openUrl={handleOpenUrl}
                            styles={style}
                            label={button.title}
                            centerMode={true}
                            compactMode={true}
                            size={(large) ? "large" : "medium"}  
                            rtl={rtl}
                            postback={button.title}
                            url={button.url}
                        />
                    </div>
                </DelayBox>
            </div>
        {/each}
    </div>
{:else}
    <div class="min-h-[34px]" 
            bind:clientHeight={clientHeight}
            style={style} >
        {#if item.text}
            <BrandResponse text={item.text} styles={style}/>
        {/if}
        {#each item.buttons as button, i}
            <div class="mx-3">
                <SimpleButton
                    on:postback={handleClick} 
                    styles={style}
                    label={button.title}
                    centerMode={true}
                    compactMode={true}
                    size="large"
                    rtl={rtl}
                    postback={button.title}
                    url={(button.url) ? button.url : ''}
                />
            </div>
        {/each}
    </div>
{/if}