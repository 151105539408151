<script>
    import { scale } from "svelte/transition";
	import SimpleButton from "./SimpleButton.svelte";
    import { createEventDispatcher } from "svelte";
	import RoundLogo from "./RoundLogo.svelte";

    export let logoURL = 'https://149528603.v2.pressablecdn.com/wp-content/uploads/2021/01/cropped-adchat-icon-1000x1000-1-32x32.png'
    export let style;
    export let height = 80;
    export let backgroundColor;
    export let hasButton = false;
    export let name = null;
    export let headerButtonCopy = "Learn More"
    export let isRound = true;
    export let rightLogoURL = null;

    let dispatch = createEventDispatcher();

    function handleHeaderClick(event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault()
        dispatch("headerClick");
    }

    function handleLogoClick(event) {
        event.stopImmediatePropagation();
        event.preventDefault()
        event.stopPropagation();
        dispatch("logoClick");
    }

    function handleButtonClick(event){
        event.stopImmediatePropagation();
        event.preventDefault()
        event.stopPropagation();
        dispatch("buttonClick");
    }

</script>

<div class={`w-full ${(style.darkMode) ? 'border-gray-800' : 'border-gray-200'} px-2`}
    on:click={handleHeaderClick}
    on:keydown={handleHeaderClick}
    style={`
        height: ${height}px; 
        ${(backgroundColor) ? "background-color: " + backgroundColor : '' };`
    }>
        <div
        class="relative h-full w-full flex items-center">
            {#if isRound}
                <div class="rounded-full"
                    on:click={handleLogoClick}>
                    <RoundLogo
                        logoURL={logoURL}
                    />
                </div>
            {:else}
                <img src={logoURL} class="h-auto w-auto my-auto cursor-pointer ml-2" alt="logo"/>
            {/if}
                {#if name}
                    <div class={`ml-2 font-bold tracking-tight text-${(style.darkMode) ? 'white' : 'black'}`}>{name}</div>
                {/if}
            {#if hasButton}
                <div class="absolute right-[-8px] h-[37px] cursor-pointer" 
                    in:scale={{duration:150, start: 0.5}}>
                    <SimpleButton 
                        styles={style}
                        centerMode={false}
                        label={headerButtonCopy} 
                        clickMode={true}
                        on:click={handleButtonClick}
                    />
                </div>
            {/if}
            {#if rightLogoURL && !isRound && rightLogoURL !== ''}
            <div class="absolute right-2 top-1/2 transform -translate-y-1/2" 
                    in:scale={{duration:150, start: 0.5}}>
                    <img
                        src={rightLogoURL}
                    />
                </div>
            {/if}
        </div>

</div>