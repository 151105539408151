<script>
    export let logoURL
    export let height = 60;
    export let width = 60;
    export let darkMode = false;
</script>

<div class={`cursor-pointer bg-no-repeat bg-center border-2 rounded-full border-white ${darkMode ? ' border-adchat-dark-gray' : ' border-adchat-medium-gray'}}`}
    style={`
        background-image: url(${logoURL});
        height: ${height}px;
        width: ${width}px;
    `}
/>